import React, { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import style from './HamburgerButton.module.scss';
import { TOPIC_IDS } from '@/js/constants';

export default function HamburgerButton() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    PubSub.publish(TOPIC_IDS.TOGGLE_MENU, isOpen);
  }, [isOpen])

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <button type="button" className={style.hamburgerButton} aria-label="メニューを開く" aria-expanded={isOpen ? 'true' : 'false'} onPointerDown={() => handleToggle()}>
      <span className={style.hamburgerButton__line}></span>
      <span className={style.hamburgerButton__line}></span>
    </button>
  )
}
